import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_HISTORY_LIST = 'START_HISTORY_LIST'
const FINISH_HISTORY_LIST = 'FINISH_HISTORY_LIST'

const initState = {
    isHistoryListFetching: false,
    historyList: [],
    totalPage: 0
}

function startGetHistoryList() {
    return {
        type: START_HISTORY_LIST
    }
}

function finishGetHistoryList(params) {
    return {
        type: FINISH_HISTORY_LIST,
        historyList: params.content,
        totalPage: params.totalPage
    }
}

export function getHistoryList(params, cb) {
    return (dispatch) => {
        dispatch(startGetHistoryList())
        apiService.list(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const { content } = res.data.data.pageInfo
                    dispatch(finishGetHistoryList(res.data.data.pageInfo))
                    if (cb) {
                        cb(content)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function historyReducer(state = initState, action) {
    switch (action.type) {
        case START_HISTORY_LIST:
            return {
                ...state,
                isHistoryListFetching: true
            }
        case FINISH_HISTORY_LIST:
            return {
                ...state,
                historyList: action.historyList,
                isHistoryListFetching: false,
                totalPage: action.totalPage
            }
        default:
            return state
    }
}
