/*eslint-disable*/
import axios from '../service'

let apiService = {}

const apis = [
    {
        name: 'list',
        url: '/412/policy/company-policy/history',
        method: 'post'
    },
    {
        name: 'calculate',
        url: '/412/policy/policy-item/calculate',
        method: 'post'
    },
    {
        name: 'review',
        url: '/412/policy/company-policy/review-list',
        method: 'post'
    },
    {
        name: 'policyFlow',
        url: '/412/policy/company-policy/flow',
        method: 'post'
    },
    {
        name: 'industryDetail',
        url: '/412/table/industry/detail',
        method: 'post'
    },
    {
        name: 'getTable',
        url: '/412/table',
        method: 'get',
        isName: true
    },
    {
        name: 'tableSave',
        url: '/412/table/company-info/save',
        method: 'post'
    },
    {
        name: 'industrySearch',
        url: '/412/table/industry/search',
        method: 'post'
    },
    {
        name: 'industrySubDetail',
        url: '/412/table/industry-sub/industryId',
        method: 'get',
        isName: true
    },
    {
        name: 'companySave',
        url: '/412/policy/company-policy/save',
        method: 'post'
    },
    {
        name: 'nonSpecificSave',
        url: '/412/policy/company-policy/non-specific/save',
        method: 'post'
    },
    {
        name: 'cognizanceList',
        url: '/412/cognizance/company-cognizance/select-list',
        method: 'post'
    },
    {
        name: 'loginByPwd',
        url: '/412/login-by-password',
        method: 'post'
    },
    {
        name: 'loginByUser',
        url: '/412/login-by-username',
        method: 'post'
    },
    {
        name: 'logout',
        url: '/412/logout',
        method: 'get'
    },
    {
        name: 'calculateNum',
        url: '/412/policy/policy-item/calculate-num',
        method: 'post'
    },
    {
        name: 'userCurrent',
        url: '/412/user/current',
        method: 'get'
    },
    {
        name: 'nonCalculate',
        url: '/412/policy/company-policy/non-specific/calculate',
        method: 'post'
    },
    {
        name: 'agentCalculate',
        url: '/412/intermediary-info/app/calculation',
        method: 'post'
    },
    {
        name: 'agentSave',
        url: '/412/intermediary-info/app/save',
        method: 'post'
    },
    {
        name: 'agentList',
        url: '/412/intermediary-info/app/select-list',
        method: 'post'
    },
    {
        name: 'agentDetail',
        url: '/412/intermediary-info/detail',
        method: 'get',
    },
    {
        name: 'calculateAll',
        url: '/412/policy/policy-item/app/calculate',
        method: 'post',
    },
    {
        name: 'tableSaveAll',
        url: '/412/table/company-info/app/save',
        method: 'post',
    },
    {
        name: 'policyDetail',
        url: '/412/policy/company-policy/app/detail',
        method: 'get',
    },
    {
        name: 'zsCalculate',
        url: '/412/building/building-info/calculation',
        method: 'post',
    },
    {
        name: 'zsSave',
        url: '/412/building/building-info/save',
        method: 'post'
    },
    {
        name: 'zsList',
        url: '/412/building/building-info/select-list',
        method: 'post'
    },
    {
        name: 'zsDetail',
        url: '/412/building/building-info/app/detail',
        method: 'get',
    },
    {
        name: 'policyList2',
        url: '/412/city-personnel-policy/list2',
        method: 'post',
    },
    {
        name: 'policyDetail2',
        url: '/412/city-personnel-policy-option/detail',
        method: 'get',
    },
]

apis.map((api) => {
    apiService[api.name] = (data) => {
        const datas = api.method === 'get' ? (api.isName ? {} : { params: Object.assign({}, data) }) : { data: Object.assign({}, data) }
        return axios(`${api.url + (api.isName ? '/' + data : '')}`, {
            method: api.method,
            ...datas
        });
    }
})

export default apiService
