import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_ZS_CALCULATE = 'START_ZS_CALCULATE'
const FINISH_ZS_CALCULATE = 'FINISH_ZS_CALCULATE'

const START_ZS_SAVE = 'START_ZS_SAVE'
const FINISH_ZS_SAVE = 'FINISH_ZS_SAVE'

const SAVE_ZS_PARAMS = 'SAVE_ZS_PARAMS'
const CLEAR_ZS_PARAMS = 'CLEAR_ZS_PARAMS'

const initState = {
    companyInfo: null,
    formParams: sessionStorage.getItem('formParamsZs') ? JSON.parse(sessionStorage.getItem('formParamsZs')) : null,
}

function startZsCalculate() {
    return {
        type: START_ZS_CALCULATE
    }
}

function finishZsCalculate(companyInfo) {
    return {
        type: FINISH_ZS_CALCULATE,
        companyInfo
    }
}

export function zsCalculate(params, cb) {
    return (dispatch) => {
        dispatch(startZsCalculate())
        apiService.zsCalculate(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishZsCalculate(res.data.data))
                    if (cb) {
                        cb()
                    }
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

function startZsSave() {
    return {
        type: START_ZS_SAVE
    }
}

function finishZsSave() {
    return {
        type: FINISH_ZS_SAVE
    }
}

export function zsSave(params, cb) {
    return (dispatch) => {
        dispatch(startZsSave())
        apiService.zsSave(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishZsSave())
                    if (cb) {
                        cb('操作成功')
                    }
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

export function saveZsForm(params) {
    return {
        type: SAVE_ZS_PARAMS,
        params
    }
}

export function clearZsForm() {
    return {
        type: CLEAR_ZS_PARAMS
    }
}


export default function zsReducer(state = initState, action) {
    switch (action.type) {
        case START_ZS_CALCULATE:
            return {
                ...state,
            }
        case FINISH_ZS_CALCULATE:
            return {
                ...state,
                companyInfo: action.companyInfo,
            }
        case START_ZS_SAVE:
            return {
                ...state,
            }
        case FINISH_ZS_SAVE:
            return {
                ...state,
            }
        case SAVE_ZS_PARAMS:
            sessionStorage.setItem('formParamsZs', JSON.stringify(action.params))
            return {
                ...state,
                formParams: action.params
            }
        case CLEAR_ZS_PARAMS:
            sessionStorage.removeItem('formParamsZs')
            return {
                ...state,
                formParams: null
            }
        default:
            return state
    }
}
