const initState = {
    hongqiaoFormParams: sessionStorage.getItem('hongqiaoFormParams') ? JSON.parse(sessionStorage.getItem('hongqiaoFormParams')) : null,
    hongqiaoList: sessionStorage.getItem('hongqiaoList') ? JSON.parse(sessionStorage.getItem('hongqiaoList')) : [],
    cultureFormParams: sessionStorage.getItem('cultureFormParams') ? JSON.parse(sessionStorage.getItem('cultureFormParams')) : null,
    cultureList: sessionStorage.getItem('cultureList') ? JSON.parse(sessionStorage.getItem('cultureList')) : [],
    listedFormParams: sessionStorage.getItem('listedFormParams') ? JSON.parse(sessionStorage.getItem('listedFormParams')) : null,
    listedList: sessionStorage.getItem('listedList') ? JSON.parse(sessionStorage.getItem('listedList')) : [],
    talentsFormParams: sessionStorage.getItem('talentsFormParams') ? JSON.parse(sessionStorage.getItem('talentsFormParams')) : null,
    talentsList: sessionStorage.getItem('talentsList') ? JSON.parse(sessionStorage.getItem('talentsList')) : [],
    knowledgeFormParams: sessionStorage.getItem('knowledgeFormParams') ? JSON.parse(sessionStorage.getItem('knowledgeFormParams')) : null,
    knowledgeList: sessionStorage.getItem('knowledgeList') ? JSON.parse(sessionStorage.getItem('knowledgeList')) : [],
    innovationFormParams: sessionStorage.getItem('innovationFormParams') ? JSON.parse(sessionStorage.getItem('innovationFormParams')) : null,
    innovationList: sessionStorage.getItem('innovationList') ? JSON.parse(sessionStorage.getItem('innovationList')) : [],
    financingFormParams: sessionStorage.getItem('financingFormParams') ? JSON.parse(sessionStorage.getItem('financingFormParams')) : null,
    financingList: sessionStorage.getItem('financingList') ? JSON.parse(sessionStorage.getItem('financingList')) : [],
    promoteFormParams: sessionStorage.getItem('promoteFormParams') ? JSON.parse(sessionStorage.getItem('promoteFormParams')) : null,
    promoteList: sessionStorage.getItem('promoteList') ? JSON.parse(sessionStorage.getItem('promoteList')) : [],
    multinationalFormParams: sessionStorage.getItem('multinationalFormParams') ? JSON.parse(sessionStorage.getItem('multinationalFormParams')) : null,
    multinationalList: sessionStorage.getItem('multinationalList') ? JSON.parse(sessionStorage.getItem('multinationalList')) : [],
    energyFormParams: sessionStorage.getItem('energyFormParams') ? JSON.parse(sessionStorage.getItem('energyFormParams')) : null,
    energyList: sessionStorage.getItem('energyList') ? JSON.parse(sessionStorage.getItem('energyList')) : [],
    cultivateFormParams: sessionStorage.getItem('cultivateFormParams') ? JSON.parse(sessionStorage.getItem('cultivateFormParams')) : null,
    cultivateList: sessionStorage.getItem('cultivateList') ? JSON.parse(sessionStorage.getItem('cultivateList')) : [],
    scienceFormParams: sessionStorage.getItem('scienceFormParams') ? JSON.parse(sessionStorage.getItem('scienceFormParams')) : null,
    scienceList: sessionStorage.getItem('scienceList') ? JSON.parse(sessionStorage.getItem('scienceList')) : [],
    lawFormParams: sessionStorage.getItem('lawFormParams') ? JSON.parse(sessionStorage.getItem('lawFormParams')) : null,
}

const SAVE_HONGQIAO_PARAMS = 'SAVE_HONGQIAO_PARAMS'
const SAVE_CULTURE_PARAMS = 'SAVE_CULTURE_PARAMS'
const SAVE_LISTED_PARAMS = 'SAVE_LISTED_PARAMS'
const SAVE_LAW_PARAMS = 'SAVE_LAW_PARAMS'
const SAVE_TALENTS_PARAMS = 'SAVE_TALENTS_PARAMS'
const SAVE_KNOWLEDGE_PARAMS = 'SAVE_KNOWLEDGE_PARAMS'
const SAVE_INNOVATION_PARAMS = 'SAVE_INNOVATION_PARAMS'
const SAVE_FINANCING_PARAMS = 'SAVE_FINANCING_PARAMS'
const SAVE_PROMOTE_PARAMS = 'SAVE_PROMOTE_PARAMS'
const SAVE_MULTINATIONAL_PARAMS = 'SAVE_MULTINATIONAL_PARAMS'
const SAVE_ENERGY_PARAMS = 'SAVE_ENERGY_PARAMS'
const SAVE_CULTIVATE_PARAMS = 'SAVE_CULTIVATE_PARAMS'
const SAVE_SCIENCE_PARAMS = 'SAVE_SCIENCE_PARAMS'

const CLEAR_ALL_PARAMS = 'CLEAR_ALL_PARAMS'

export function saveOneForm(params, list, type) {
    return {
        type,
        params,
        list
    }
}

export function clearAllForm() {
    return {
        type: CLEAR_ALL_PARAMS
    }
}

export default function allReducer(state = initState, action) {
    switch (action.type) {
        case SAVE_HONGQIAO_PARAMS:
            sessionStorage.setItem('hongqiaoFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('hongqiaoList', JSON.stringify(action.list))
            return {
                ...state,
                hongqiaoFormParams: action.params,
                hongqiaoList: action.list
            }
        case SAVE_CULTURE_PARAMS:
            sessionStorage.setItem('cultureFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('cultureList', JSON.stringify(action.list))
            return {
                ...state,
                cultureFormParams: action.params,
                cultureList: action.list
            }
        case SAVE_LISTED_PARAMS:
            sessionStorage.setItem('listedFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('listedList', JSON.stringify(action.list))
            return {
                ...state,
                listedFormParams: action.params,
                listedList: action.list
            }
        case SAVE_TALENTS_PARAMS:
            sessionStorage.setItem('talentsFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('talentsList', JSON.stringify(action.list))
            return {
                ...state,
                talentsFormParams: action.params,
                talentsList: action.list
            }
        case SAVE_KNOWLEDGE_PARAMS:
            sessionStorage.setItem('knowledgeFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('knowledgeList', JSON.stringify(action.list))
            return {
                ...state,
                knowledgeFormParams: action.params,
                knowledgeList: action.list
            }
        case SAVE_INNOVATION_PARAMS:
            sessionStorage.setItem('innovationFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('innovationList', JSON.stringify(action.list))
            return {
                ...state,
                innovationFormParams: action.params,
                innovationList: action.list
            }
        case SAVE_FINANCING_PARAMS:
            sessionStorage.setItem('financingFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('financingList', JSON.stringify(action.list))
            return {
                ...state,
                financingFormParams: action.params,
                financingList: action.list
            }
        case SAVE_PROMOTE_PARAMS:
            sessionStorage.setItem('promoteFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('promoteList', JSON.stringify(action.list))
            return {
                ...state,
                promoteFormParams: action.params,
                promoteList: action.list
            }
        case SAVE_MULTINATIONAL_PARAMS:
            sessionStorage.setItem('multinationalFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('multinationalList', JSON.stringify(action.list))
            return {
                ...state,
                multinationalFormParams: action.params,
                multinationalList: action.list
            }
        case SAVE_ENERGY_PARAMS:
            sessionStorage.setItem('energyFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('energyList', JSON.stringify(action.list))
            return {
                ...state,
                energyFormParams: action.params,
                energyList: action.list
            }
        case SAVE_CULTIVATE_PARAMS:
            sessionStorage.setItem('cultivateFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('cultivateList', JSON.stringify(action.list))
            return {
                ...state,
                cultivateFormParams: action.params,
                cultivateList: action.list
            }
        case SAVE_SCIENCE_PARAMS:
            sessionStorage.setItem('scienceFormParams', JSON.stringify(action.params))
            sessionStorage.setItem('scienceList', JSON.stringify(action.list))
            return {
                ...state,
                scienceFormParams: action.params,
                scienceList: action.list
            }
        case SAVE_LAW_PARAMS:
            sessionStorage.setItem('lawFormParams', JSON.stringify(action.params))
            return {
                ...state,
                lawFormParams: action.params,
            }
        case CLEAR_ALL_PARAMS:
            sessionStorage.removeItem('hongqiaoFormParams')
            sessionStorage.removeItem('hongqiaoList')
            sessionStorage.removeItem('cultureFormParams')
            sessionStorage.removeItem('cultureList')
            sessionStorage.removeItem('listedFormParams')
            sessionStorage.removeItem('listedList')
            sessionStorage.removeItem('talentsFormParams')
            sessionStorage.removeItem('talentsList')
            sessionStorage.removeItem('knowledgeFormParams')
            sessionStorage.removeItem('knowledgeList')
            sessionStorage.removeItem('innovationFormParams')
            sessionStorage.removeItem('innovationList')
            sessionStorage.removeItem('financingFormParams')
            sessionStorage.removeItem('financingList')
            sessionStorage.removeItem('promoteFormParams')
            sessionStorage.removeItem('promoteList')
            sessionStorage.removeItem('multinationalFormParams')
            sessionStorage.removeItem('multinationalList')
            sessionStorage.removeItem('energyFormParams')
            sessionStorage.removeItem('energyList')
            sessionStorage.removeItem('cultivateFormParams')
            sessionStorage.removeItem('cultivateList')
            sessionStorage.removeItem('scienceFormParams')
            sessionStorage.removeItem('scienceList')
            sessionStorage.removeItem('lawFormParams')
            return {
                ...state,
                hongqiaoFormParams: null,
                hongqiaoList: [],
                cultureFormParams: null,
                cultureList: [],
                listedFormParams: null,
                listedList: [],
                talentsFormParams: null,
                talentsList: [],
                knowledgeFormParams: null,
                knowledgeList: [],
                innovationFormParams: null,
                innovationList: [],
                financingFormParams: null,
                financingList: [],
                promoteFormParams: null,
                promoteList: [],
                multinationalFormParams: null,
                multinationalList: [],
                energyFormParams: null,
                energyList: [],
                cultivateFormParams: null,
                cultivateList: [],
                scienceFormParams: null,
                scienceList: [],
                lawFormParams: null
            }
        default:
            return state
    }
}
