import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_INDUSTRY_DETAIL = 'START_INDUSTRY_DETAIL'
const FINISH_INDUSTRY_DETAIL = 'FINISH_INDUSTRY_DETAIL'

const START_INDUSTRY_SEARCH = 'START_INDUSTRY_SEARCH'
const FINISH_INDUSTRY_SEARCH = 'FINISH_INDUSTRY_SEARCH'

const START_INDUSTRY_SUB_DETAIL = 'START_INDUSTRY_SUB_DETAIL'
const FINISH_INDUSTRY_SUB_DETAIL = 'FINISH_INDUSTRY_SUB_DETAIL'

const FINISH_INDUSTRY_SUB_SEARCH = 'FINISH_INDUSTRY_SUB_SEARCH'
const SAVE_INFO = 'SAVE_INFO'
const CLEAR_INFO = 'CLEAR_INFO'

const initState = {
    industryList: [],
    industrySubList: [],
    industrySaveInfo: sessionStorage.getItem('industrySaveInfo') ? JSON.parse(sessionStorage.getItem('industrySaveInfo')) : {}
}

/* ***************************** 一级 ********************************* */

function startIndustryDetail() {
    return {
        type: START_INDUSTRY_DETAIL
    }
}

function finishIndustryDetail(industryList) {
    return {
        type: FINISH_INDUSTRY_DETAIL,
        industryList
    }
}

export function getIndustryDetail(params) {
    return (dispatch) => {
        dispatch(startIndustryDetail())
        apiService.industryDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishIndustryDetail(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}

function startIndustrySearch() {
    return {
        type: START_INDUSTRY_SEARCH
    }
}

function finishIndustrySearch(data, listname) {
    let params = listname === 'industryList' ?
        { type: FINISH_INDUSTRY_SEARCH, industryList: data } :
        { type: FINISH_INDUSTRY_SUB_SEARCH, industrySubList: data }
    return params
}

export function getIndustrySearch(params, listname) {
    return (dispatch) => {
        dispatch(startIndustrySearch())
        apiService.industrySearch(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishIndustrySearch(res.data.data, listname))
                }
            })
            .catch(() => {
                return
            })
    }
}

function startIndustrySubDetail() {
    return {
        type: START_INDUSTRY_SUB_DETAIL
    }
}

function finishIndustrySubDetail(industrySubList) {
    return {
        type: FINISH_INDUSTRY_SUB_DETAIL,
        industrySubList
    }
}

export function getIndustrySubDetail(params, cb) {
    return (dispatch) => {
        dispatch(startIndustrySubDetail())
        apiService.industrySubDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishIndustrySubDetail(res.data.data))
                    if (cb) {
                        cb(res.data.data)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export function saveIndustryInfo(params) {
    return {
        type: SAVE_INFO,
        industrySaveInfo: params
    }
}

export function clearIndustryInfo() {
    return {
        type: CLEAR_INFO
    }
}

export default function industryReducer(state = initState, action) {
    switch (action.type) {
        case START_INDUSTRY_DETAIL:
            return {
                ...state
            }
        case FINISH_INDUSTRY_DETAIL:
            return {
                ...state,
                industryList: action.industryList
            }
        case START_INDUSTRY_SEARCH:
            return {
                ...state
            }
        case FINISH_INDUSTRY_SEARCH:
            return {
                ...state,
                industryList: action.industryList
            }
        case FINISH_INDUSTRY_SUB_SEARCH:
            return {
                ...state,
                industrySubList: action.industrySubList
            }
        case START_INDUSTRY_SUB_DETAIL:
            return {
                ...state
            }
        case FINISH_INDUSTRY_SUB_DETAIL:
            return {
                ...state,
                industrySubList: action.industrySubList
            }
        case SAVE_INFO:
            sessionStorage.setItem('industrySaveInfo', JSON.stringify(action.industrySaveInfo))
            return {
                ...state,
                industrySaveInfo: action.industrySaveInfo
            }
        case CLEAR_INFO:
            sessionStorage.removeItem('industrySaveInfo')
            return {
                ...state,
                industrySaveInfo: {}
            }
        default:
            return state
    }
}
