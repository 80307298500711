import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_CALCULATE_NUM = 'START_CALCULATE_NUM'
const FINISH_CALCULATE_NUM = 'FINISH_CALCULATE_NUM'

const START_CALCULATE = 'START_CALCULATE'
const FINISH_CALCULATE = 'FINISH_CALCULATE'

const START_TABLE = 'START_TABLE'
const FINISH_TABLE = 'FINISH_TABLE'

const CLEAR_PARAMS = 'CLEAR_PARAMS'
const SAVE_PARAMS = 'SAVE_PARAMS'

const START_CALCULATE_ALL = 'START_CALCULATE_ALL'
const FINISH_CALCULATE_ALL = 'FINISH_CALCULATE_ALL'

const initState = {
    companyInfo: null,
    directPolicyList: [],
    totalDirectAmount: 0,
    recommendPolicyList: [],
    totalRecommendAmount: 0,
    formParams: sessionStorage.getItem('formParams') ? JSON.parse(sessionStorage.getItem('formParams')) : null,
    calculateList: sessionStorage.getItem('calculateList') ? JSON.parse(sessionStorage.getItem('calculateList')) : [],
    directPolicyNum: 0,
    recommendPolicyNum: 0,
    companyResult: null
}

function startCalculateNum() {
    return {
        type: START_CALCULATE_NUM
    }
}

function finishCalculateNum(directPolicyNum, recommendPolicyNum, params, calculateList) {
    return {
        type: FINISH_CALCULATE_NUM,
        directPolicyNum,
        recommendPolicyNum,
        params,
        calculateList
    }
}

export function toCalculateNum(params, list) {
    return (dispatch) => {
        dispatch(startCalculateNum())
        apiService.calculateNum(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const { directPolicyNum, recommendPolicyNum } = res.data.data
                    dispatch(finishCalculateNum(directPolicyNum, recommendPolicyNum, params, list))
                }
            })
            .catch(() => {
                return
            })
    }
}

function startCalculate() {
    return {
        type: START_CALCULATE
    }
}

function finishCalculate(companyInfo, directPolicyList, totalDirectAmount, recommendPolicyList, totalRecommendAmount) {
    return {
        type: FINISH_CALCULATE,
        companyInfo,
        directPolicyList,
        totalDirectAmount,
        recommendPolicyList,
        totalRecommendAmount
    }
}

export function toCalculate(params) {
    return (dispatch) => {
        dispatch(startCalculate())
        apiService.calculate(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const { companyInfo, directPolicyList, totalDirectAmount, recommendPolicyList, totalRecommendAmount } = res.data.data
                    dispatch(finishCalculate(companyInfo, directPolicyList, totalDirectAmount, recommendPolicyList, totalRecommendAmount))
                }
            })
            .catch(() => {
                return
            })
    }
}

function startCalculateAll() {
    return {
        type: START_CALCULATE_ALL
    }
}

function finishCalculateAll(companyResult) {
    return {
        type: FINISH_CALCULATE_ALL,
        companyResult
    }
}

export function toCalculateAll(params) {
    return (dispatch) => {
        dispatch(startCalculateAll())
        apiService.calculateAll(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCalculateAll(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}

function startGetTable() {
    return {
        type: START_TABLE
    }
}

function finishGetTable() {
    return {
        type: FINISH_TABLE
    }
}

export function getTable(params, cb) {
    return (dispatch) => {
        dispatch(startGetTable())
        apiService.getTable(`${params}/1`)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishGetTable())
                    if (cb) {
                        cb(res.data.data.tableFieldGroupVos)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export function saveCalculateForm(params, calculateList) {
    return {
        type: SAVE_PARAMS,
        params,
        calculateList
    }
}

export function clearCalculateForm() {
    return {
        type: CLEAR_PARAMS
    }
}

export default function calculateReducer(state = initState, action) {
    switch (action.type) {
        case START_CALCULATE_NUM:
            return {
                ...state
            }
        case FINISH_CALCULATE_NUM:
            sessionStorage.setItem('formParams', JSON.stringify(action.params))
            sessionStorage.setItem('calculateList', JSON.stringify(action.calculateList))
            return {
                ...state,
                directPolicyNum: action.directPolicyNum,
                recommendPolicyNum: action.recommendPolicyNum,
                formParams: action.params,
                calculateList: action.calculateList
            }
        case START_CALCULATE:
            return {
                ...state
            }
        case FINISH_CALCULATE:
            return {
                ...state,
                companyInfo: action.companyInfo,
                directPolicyList: action.directPolicyList,
                totalDirectAmount: action.totalDirectAmount,
                recommendPolicyList: action.recommendPolicyList,
                totalRecommendAmount: action.totalRecommendAmount
            }
        case START_CALCULATE_ALL:
            return {
                ...state
            }
        case FINISH_CALCULATE_ALL:
            return {
                ...state,
                companyResult: action.companyResult,
            }
        case START_TABLE:
            return {
                ...state
            }
        case FINISH_TABLE:
            return {
                ...state
            }
        case SAVE_PARAMS:
            sessionStorage.setItem('formParams', JSON.stringify(action.params))
            sessionStorage.setItem('calculateList', JSON.stringify(action.calculateList))
            return {
                ...state,
                formParams: action.params,
                calculateList: action.calculateList
            }
        case CLEAR_PARAMS:
            sessionStorage.removeItem('formParams')
            sessionStorage.removeItem('calculateList')
            return {
                ...state,
                calculateList: [],
                formParams: null
            }
        default:
            return state
    }
}
