import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_CHANGE_FLOW = 'START_CHANGE_FLOW'
const FINISH_CHANGE_FLOW = 'FINISH_CHANGE_FLOW'
const FAIL_CHANGE_FLOW = 'FAIL_CHANGE_FLOW'

const initState = {
    changeFlowFetching: false
}

function startChangeFlow() {
    return {
        type: START_CHANGE_FLOW
    }
}

function finishChangeFlow() {
    return {
        type: FINISH_CHANGE_FLOW
    }
}

function failChangeFlow() {
    return {
        type: FAIL_CHANGE_FLOW
    }
}

export function changeFlow(params, cb) {
    return (dispatch) => {
        dispatch(startChangeFlow())
        apiService.policyFlow(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishChangeFlow())
                    if (cb) {
                        cb('操作成功')
                    }
                } else {
                    dispatch(failChangeFlow())
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

export default function auditReducer(state = initState, action) {
    switch (action.type) {
        case START_CHANGE_FLOW:
            return {
                ...state,
                changeFlowFetching: true
            }
        case FINISH_CHANGE_FLOW:
            return {
                ...state,
                changeFlowFetching: false
            }
        case FAIL_CHANGE_FLOW:
            return {
                ...state,
                changeFlowFetching: false
            }
        default:
            return state
    }
}
