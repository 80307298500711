import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_DETAIL = 'START_DETAIL'
const FINISH_DETAIL = 'FINISH_DETAIL'

const initState = {
    companyResult: null
}

function startDetail() {
    return {
        type: START_DETAIL
    }
}

function finishDetail(companyResult) {
    return {
        type: FINISH_DETAIL,
        companyResult,
    }
}

export function getDetail(params, cb) {
    return (dispatch) => {
        dispatch(startDetail())
        apiService.policyDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishDetail(res.data.data))
                    if (cb) {
                        cb()
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function resultReducer(state = initState, action) {
    switch (action.type) {
        case START_DETAIL:
            return {
                ...state
            }
        case FINISH_DETAIL:
            return {
                ...state,
                companyResult: action.companyResult,
            }
        default:
            return state
    }
}
