import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_NON_SPECIFIC_SAVE = 'START_NON_SPECIFIC_SAVE'
const FINISH_NON_SPECIFIC_SAVE = 'FINISH_NON_SPECIFIC_SAVE'

const START_NON_CALCULATE = 'START_NON_CALCULATE'
const FINISH_NON_CALCULATE = 'FINISH_NON_CALCULATE'

const initState = {
    nonCompanyInfo: {}
}

function startNonSpecificSave() {
    return {
        type: START_NON_SPECIFIC_SAVE
    }
}

function finishNonSpecificSave() {
    return {
        type: FINISH_NON_SPECIFIC_SAVE
    }
}

export function nonSpecificSaveFun(params, cb, errorCb) {
    return (dispatch) => {
        dispatch(startNonSpecificSave())
        apiService.nonSpecificSave(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishNonSpecificSave())
                    if (cb) {
                        cb(res.data.data)
                    }
                } else {
                    if (res.data.resultCode === 12) {
                        if (errorCb) {
                            errorCb(res.data.resultMessage)
                        }
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

function startNonCalculate() {
    return {
        type: START_NON_CALCULATE
    }
}

function finishNonCalculate(nonCompanyInfo) {
    return {
        type: FINISH_NON_CALCULATE,
        nonCompanyInfo
    }
}

export function nonCalculateFun(params) {
    return (dispatch) => {
        dispatch(startNonCalculate())
        apiService.nonCalculate(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishNonCalculate(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function recommendedNonReducer(state = initState, action) {
    switch (action.type) {
        case START_NON_SPECIFIC_SAVE:
            return {
                ...state
            }
        case FINISH_NON_SPECIFIC_SAVE:
            return {
                ...state
            }
        case START_NON_CALCULATE:
            return {
                ...state
            }
        case FINISH_NON_CALCULATE:
            return {
                ...state,
                nonCompanyInfo: action.nonCompanyInfo
            }
        default:
            return state
    }
}
