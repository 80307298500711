import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'
import {push} from 'react-router-redux'

const START_LOGIN = 'START_LOGIN'
const FINISH_LOGIN = 'FINISH_LOGIN'
const FAIL_LOGIN = 'FAIL_LOGIN'

const START_LOGOUT = 'START_LOGOUT'
const FINISH_LOGOUT = 'FINISH_LOGOUT'

const initState = {
    isFetching: false
}

function startLogin() {
    window.localStorage.removeItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator")
    return {
        type: START_LOGIN
    }
}

function finishLogin() {
    return {
        type: FINISH_LOGIN
    }
}

function failLogin() {
    return {
        type: FAIL_LOGIN
    }
}

/**
 * login or login by password
 * @param {*} params
 * @param {*} type 2: by password
 */
export function login(params, type) {
    let api = null
    if (type === 2) {
        api = 'loginByPwd'
    } else if (type === 3) {
        api = 'userCurrent'
    } else if (type === 4) {
        api = 'loginByUser'
    }
    return (dispatch) => {
        dispatch(startLogin())
        apiService[api](params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    if (type === 3) {
                        res.data.data.jwtToken = global.userInfo.jwtToken
                    }
                    window.localStorage.setItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator", JSON.stringify(res.data.data))
                    global.userInfo = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator"))
                    dispatch(finishLogin())

                    if (type === 1 || type === 3) {
                        dispatch(push('/home/1'))
                    } else if (type === 2) {
                        let loginList = JSON.parse(window.localStorage.getItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator_login"))
                        if (!loginList || loginList.length === 0) {
                            loginList = []
                        }
                        if (loginList.findIndex(e => e.userName === params.userName) === -1) {
                            params.nickname = res.data.data.nickname
                            loginList.push(params)
                        }
                        window.localStorage.setItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator_login", JSON.stringify(loginList))
                        dispatch(push('/home/0'))
                    } else if (type === 4) {
                        dispatch(push('/home/0'))
                    }
                } else {
                    dispatch(failLogin())
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

function startLogout() {
    return {
        type: START_LOGOUT
    }
}

function finishLogout() {
    window.localStorage.removeItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator")
    return {
        type: FINISH_LOGOUT
    }
}

export function logout(params) {
    return (dispatch) => {
        dispatch(startLogout())
        apiService.logout(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishLogout())
                    dispatch(push('/index'))
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

export default function loginReducer(state = initState, action) {
    switch (action.type) {
        case START_LOGIN:
            return {
                ...state,
                isFetching: true
            }
        case FINISH_LOGIN:
            return {
                ...state,
                isFetching: false
            }
        case FAIL_LOGIN:
            return {
                ...state,
                isFetching: false
            }
        case START_LOGOUT:
            return {
                ...state
            }
        case FINISH_LOGOUT:
            return {
                ...state
            }
        default:
            return state
    }
}
