import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_AGENT_CALCULATE = 'START_AGENT_CALCULATE'
const FINISH_AGENT_CALCULATE = 'FINISH_AGENT_CALCULATE'

const START_AGENT_SAVE = 'START_AGENT_SAVE'
const FINISH_AGENT_SAVE = 'FINISH_AGENT_SAVE'

const SAVE_AGENT_PARAMS = 'SAVE_AGENT_PARAMS'
const CLEAR_AGENT_PARAMS = 'CLEAR_AGENT_PARAMS'

const initState = {
    companyInfo: null,
    formParams: sessionStorage.getItem('formParamsAgent') ? JSON.parse(sessionStorage.getItem('formParamsAgent')) : null,
}

function startAgentCalculate() {
    return {
        type: START_AGENT_CALCULATE
    }
}

function finishAgentCalculate(companyInfo) {
    return {
        type: FINISH_AGENT_CALCULATE,
        companyInfo
    }
}

export function agentCalculate(params, cb) {
    return (dispatch) => {
        dispatch(startAgentCalculate())
        apiService.agentCalculate(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishAgentCalculate(res.data.data))
                    if (cb) {
                        cb()
                    }
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

function startAgentSave() {
    return {
        type: START_AGENT_SAVE
    }
}

function finishAgentSave() {
    return {
        type: FINISH_AGENT_SAVE
    }
}

export function agentSave(params, cb, errorCb) {
    return (dispatch) => {
        dispatch(startAgentSave())
        apiService.agentSave(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishAgentSave())
                    if (cb) {
                        cb('操作成功')
                    }
                }
            })
            .catch(() => {
                console.log('error')
            })
    }
}

export function saveAgentForm(params) {
    return {
        type: SAVE_AGENT_PARAMS,
        params
    }
}

export function clearAgentForm() {
    console.log('hi~');
    return {
        type: CLEAR_AGENT_PARAMS
    }
}


export default function agentReducer(state = initState, action) {
    switch (action.type) {
        case START_AGENT_CALCULATE:
            return {
                ...state,
            }
        case FINISH_AGENT_CALCULATE:
            return {
                ...state,
                companyInfo: action.companyInfo,
            }
        case START_AGENT_SAVE:
            return {
                ...state,
            }
        case FINISH_AGENT_SAVE:
            return {
                ...state,
            }
        case SAVE_AGENT_PARAMS:
            sessionStorage.setItem('formParamsAgent', JSON.stringify(action.params))
            return {
                ...state,
                formParams: action.params
            }
        case CLEAR_AGENT_PARAMS:
            sessionStorage.removeItem('formParamsAgent')
            return {
                ...state,
                formParams: null
            }
        default:
            return state
    }
}
