import { Modal, Toast } from 'antd-mobile'
import axios from 'axios'
import {createHashHistory} from 'history'

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DOMAIN,
    headers: { 'Content-Type': 'application/json; charset=UTF-8' }
})

instance.interceptors.request.use(
    (config) => {
        const token = global.userInfo ? global.userInfo.jwtToken : null
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    })

instance.interceptors.response.use(
    (response) => {
        return response
    }, (error) => {
        if (error && error.response && error.response.status < 400) {
            return Promise.reject(error)
        } else {
            // 请求接口400-500
            const history = createHashHistory();
            if (history.location.pathname.indexOf('home') >= 0) {
                Toast.offline('网络出错了, 请稍后再试', 2);
            } else {
                Modal.alert('网络出错了', '是否返回登陆，重新登陆', [
                    { text: '取消', onPress: () => history.push('/index')},
                    { text: '确认', onPress: () => history.push('/index')},
                ])
            }
            return Promise.reject(error)
        }
    })

export function handleResultCode(response, onErrCb) {
    if (response.status && response.data) {
        const history = createHashHistory()
        switch (response.data.resultCode) {
            case 0:
                return { success: true, data: response.data }
            case 1:
            case 110:
            case 300:
            case 403: {
                Modal.alert(response.data.displayMessage || response.data.resultMessage, null, [
                    { text: '取消', onPress: () => {if(onErrCb) onErrCb()}},
                    { text: '确认', onPress: () => {if(onErrCb) onErrCb()}},
                ])
                return { success: false, data: response.data }
            }
            case 11: {
                Modal.alert(response.data.resultMessage, null, [
                    { text: '取消', onPress: () => history.push('/index')},
                    { text: '确认', onPress: () => history.push('/index')},
                ])
                return { success: false, data: response.data }
            }
            case 400: {
                Modal.alert(response.data.resultMessage, null, [
                    { text: '取消', onPress: () => window.location.reload()},
                    { text: '确认', onPress: () => window.location.reload()},
                ])
                return { success: false, data: response.data }
            }
            case 401:
            case 100: {
                window.localStorage.removeItem(process.env.REACT_APP_ENV_NAME + "_zc_calculator")
                window.location.reload()
                break
            }
            default:
                return { success: false, data: response.data }
        }
    } else {
        return {
            success: false,
            data: response
        }
    }
}

export default instance
