import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_HISTORY_ZSLIST = 'START_HISTORY_ZSLIST'
const FINISH_HISTORY_ZSLIST = 'FINISH_HISTORY_ZSLIST'

const initState = {
    isHistoryZsListFetching: false,
    historyList: [],
    totalPage: 0
}

function startGetHistoryZsList() {
    return {
        type: START_HISTORY_ZSLIST
    }
}

function finishGetHistoryZsList(params) {
    return {
        type: FINISH_HISTORY_ZSLIST,
        historyList: params.content,
        totalPage: params.totalPage
    }
}

export function getHistoryZsList(params, cb) {
    return (dispatch) => {
        dispatch(startGetHistoryZsList())
        apiService.zsList(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const { content } = res.data.data.pageInfo
                    dispatch(finishGetHistoryZsList(res.data.data.pageInfo))
                    if (cb) {
                        cb(content)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function historyZsReducer(state = initState, action) {
    switch (action.type) {
        case START_HISTORY_ZSLIST:
            return {
                ...state,
                isHistoryZsListFetching: true
            }
        case FINISH_HISTORY_ZSLIST:
            return {
                ...state,
                historyList: action.historyList,
                isHistoryZsListFetching: false,
                totalPage: action.totalPage
            }
        default:
            return state
    }
}
