import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_TABLE_SAVE = 'START_TABLE_SAVE'
const FINISH_TABLE_SAVE = 'FINISH_TABLE_SAVE'

const START_COMPANY_SAVE = 'START_COMPANY_SAVE'
const FINISH_COMPANY_SAVE = 'FINISH_COMPANY_SAVE'

const START_TABLE_SAVE_ALL = 'START_TABLE_SAVE_ALL'
const FINISH_TABLE_SAVE_ALL = 'FINISH_TABLE_SAVE_ALL'

const initState = {
}

function startCalculate() {
    return {
        type: START_TABLE_SAVE
    }
}

function finishCalculate() {
    return {
        type: FINISH_TABLE_SAVE
    }
}

export function tableSave(params, cb, errorCb) {
    return (dispatch) => {
        dispatch(startCalculate())
        apiService.tableSave(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCalculate())
                    if (cb) {
                        cb(res.data.data)
                    }
                } else {
                    if (res.data.resultCode === 12) {
                        if (errorCb) {
                            errorCb(res.data.resultMessage)
                        }
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

function startCalculateAll() {
    return {
        type: START_TABLE_SAVE_ALL
    }
}

function finishCalculateAll() {
    return {
        type: FINISH_TABLE_SAVE_ALL
    }
}

export function tableSaveAll(params, cb, errorCb) {
    return (dispatch) => {
        dispatch(startCalculateAll())
        apiService.tableSaveAll(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCalculateAll())
                    if (cb) {
                        cb(res.data.data)
                    }
                } else {
                    if (res.data.resultCode === 12) {
                        if (errorCb) {
                            errorCb(res.data.resultMessage)
                        }
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

function startCompanySave() {
    return {
        type: START_COMPANY_SAVE
    }
}

function finishCompanySave() {
    return {
        type: FINISH_COMPANY_SAVE
    }
}

export function companySave(params, cb) {
    return (dispatch) => {
        dispatch(startCompanySave())
        apiService.companySave(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishCompanySave())
                    if (cb) {
                        cb(res.data.data)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function recommendedReducer(state = initState, action) {
    switch (action.type) {
        case START_TABLE_SAVE:
            return {
                ...state
            }
        case FINISH_TABLE_SAVE:
            return {
                ...state
            }
        case START_COMPANY_SAVE:
            return {
                ...state
            }
        case FINISH_COMPANY_SAVE:
            return {
                ...state
            }
        case START_TABLE_SAVE_ALL:
            return {
                ...state
            }
        case FINISH_TABLE_SAVE_ALL:
            return {
                ...state
            }
        default:
            return state
    }
}
