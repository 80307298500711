import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_DETAIL = 'START_DETAIL'
const FINISH_DETAIL = 'FINISH_DETAIL'

const initState = {
    companyInfo: {},
}

function startDetail() {
    return {
        type: START_DETAIL
    }
}

function finishDetail(companyInfo) {
    return {
        type: FINISH_DETAIL,
        companyInfo,
    }
}

export function getDetail(params) {
    return (dispatch) => {
        dispatch(startDetail())
        apiService.zsDetail(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishDetail(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function resultZsReducer(state = initState, action) {
    switch (action.type) {
        case START_DETAIL:
            return {
                ...state
            }
        case FINISH_DETAIL:
            return {
                ...state,
                companyInfo: action.companyInfo,
            }
        default:
            return state
    }
}
