const SAVE_DETAIL_INFO = 'SAVE_DETAIL_INFO'
const CLEAR_DETAIL_INFO = 'CLEAR_DETAIL_INFO'

const initState = {
    detailInfo: sessionStorage.getItem('detailInfo') ? JSON.parse(sessionStorage.getItem('detailInfo')) : {},
}

export function saveDetailInfo(params) {
    return {
        type: SAVE_DETAIL_INFO,
        detailInfo: params
    }
}

export function clearDetailInfo() {
    return {
        type: CLEAR_DETAIL_INFO
    }
}

export default function detailReducer(state = initState, action) {
    switch (action.type) {
        case SAVE_DETAIL_INFO:
            sessionStorage.setItem('detailInfo', JSON.stringify(action.detailInfo))
            return {
                ...state,
                detailInfo: action.detailInfo
            }
        case CLEAR_DETAIL_INFO:
            sessionStorage.removeItem('detailInfo')
            return {
                ...state,
                detailInfo: {}
            }
        default:
            return state
    }
}
