import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_LIST = 'START_LIST'
const FINISH_LIST = 'FINISH_LIST'

const START_DETAIL = 'START_DETAIL'
const FINISH_DETAIL = 'FINISH_DETAIL'

const initState = {
    policyList: [],
    policyDetail: [],
    detailLoading: false
}

function startGetPolicyList2() {
    return {
        type: START_LIST
    }
}

function finishGetPolicyList2(policyList) {
    return {
        type: FINISH_LIST,
        policyList,
    }
}

export function getPolicyList2(params) {
    return (dispatch) => {
        dispatch(startGetPolicyList2())
        apiService.policyList2(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishGetPolicyList2(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}


function startGetPolicyDetail2() {
    return {
        type: START_DETAIL,
    }
}

function finishGetPolicyDetail2(policyDetail) {
    return {
        type: FINISH_DETAIL,
        policyDetail,
    }
}

export function getPolicyDetail2(params) {
    return (dispatch) => {
        dispatch(startGetPolicyDetail2())
        apiService.policyDetail2(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    dispatch(finishGetPolicyDetail2(res.data.data))
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function policyNewReducer(state = initState, action) {
    switch (action.type) {
        case START_LIST:
            return {
                ...state
            }
        case FINISH_LIST:
            return {
                ...state,
                policyList: action.policyList,
            }
        case START_DETAIL:
            return {
                ...state,
                detailLoading: true
            }
        case FINISH_DETAIL:
            return {
                ...state,
                policyDetail: action.policyDetail,
                detailLoading: false
            }
        default:
            return state
    }
}
