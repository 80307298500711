import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import RouterMap from './router/RooterMap'
import configStore from './redux/configStore'
import {unregister} from './serviceWorker'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import './config'
const store = configStore()

window.addEventListener('focusout', function () {
    window.scroll(0, 0);
})

ReactDOM.render(
    <Provider store={store}>
        <ConfigProvider locale={zhCN}>
            <RouterMap />
            <canvas id="myCanvas" style={{display: 'none'}}></canvas>
        </ConfigProvider>
    </Provider>,
    document.getElementById('root')
)
unregister()
