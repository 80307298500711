import * as React from 'react'
import * as Loadable from 'react-loadable'
import {Redirect, Route, Switch } from 'react-router-dom'
import {ConnectedRouter} from 'connected-react-router'
import {createHashHistory} from 'history'
import loading from '../components/Loading/loading'
import 'antd/dist/antd.less'
import 'antd-mobile/dist/antd-mobile.css'
import '../styles/global.less'

const RooterMap = () => {
    return (<ConnectedRouter history={createHashHistory()}>
        <Switch>
            <Redirect from="/" to={'/index'} exact/>
            <Route path="/index" exact component={Loadable({loader: ()=>import('../pages/index/index'), loading})} />
            <Route path="/home/:type" exact component={Loadable({loader: ()=>import('../pages/home/index'), loading})} />
            <Route path="/auth" exact={true} component={Loadable({loader: ()=>import('../pages/auth/index'), loading})} />
            <Route path="/history" exact component={Loadable({loader: ()=>import('../pages/history/index'), loading})} />
            <Route path="/history-non" exact component={Loadable({loader: ()=>import('../pages/history-non/index'), loading})} />
            <Route path="/review" exact component={Loadable({loader: ()=>import('../pages/review/index'), loading})} />
            <Route path="/calculate/:index/:tableId/:industryId/:industryCategory" exact component={Loadable({loader: ()=>import('../pages/calculate/index'), loading})} />
            <Route path="/calculate-edit/:index/:tableId/:industryId/:industryCategory" exact component={Loadable({loader: ()=>import('../pages/calculate/index'), loading})} />
            <Route path="/recommended/:index/:from" exact component={Loadable({loader: ()=>import('../pages/recommended/index'), loading})} />
            <Route path="/recommended-non/:index" exact component={Loadable({loader: ()=>import('../pages/recommended-non/index'), loading})} />
            <Route path="/detail/:index/:from" exact component={Loadable({loader: ()=>import('../pages/detail/index'), loading})} />
            <Route path="/result/:id/:from" exact component={Loadable({loader: ()=>import('../pages/result/index'), loading})} />
            <Route path="/result-non/:id/:from" exact component={Loadable({loader: ()=>import('../pages/result-non/index'), loading})} />
            <Route path="/audit/:id" exact component={Loadable({loader: ()=>import('../pages/audit/index'), loading})} />
            <Route path="/audit-home" exact component={Loadable({loader: ()=>import('../pages/audit-home/index'), loading})} />
            <Route path="/industry" exact component={Loadable({loader: ()=>import('../pages/industry/index'), loading})} />
            <Route path="/industry-sub/:id/:industryCategory" exact component={Loadable({loader: ()=>import('../pages/industry-sub/index'), loading})} />
            <Route path="/company-info" exact component={Loadable({loader: ()=>import('../pages/company-info/index'), loading})} />
            <Route path="/company-info-non" exact component={Loadable({loader: ()=>import('../pages/company-info-non/index'), loading})} />
            <Route path="/company-info-detail" exact component={Loadable({loader: ()=>import('../pages/company-info-detail/index'), loading})} />
            <Route path="/search/:industryId/:industryCategory" exact component={Loadable({loader: ()=>import('../pages/search/index'), loading})} />
            <Route path="/success" exact component={Loadable({loader: ()=>import('../pages/success/index'), loading})} />
            <Route path="/cognizance-detail" exact component={Loadable({loader: ()=>import('../pages/cognizance-detail/index'), loading})} />
            <Route path="/cognizance-history" exact component={Loadable({loader: ()=>import('../pages/cognizance-history/index'), loading})} />
            <Route path="/cognizance-upload" exact component={Loadable({loader: ()=>import('../pages/cognizance-upload/index'), loading})} />
            <Route path="/agreement/:type" exact component={Loadable({loader: ()=>import('../pages/agreement/index'), loading})} />
            <Route path="/login" exact component={Loadable({loader: ()=>import('../pages/login/index'), loading})} />
            <Route path="/login-history" exact component={Loadable({loader: ()=>import('../pages/login-history/index'), loading})} />
            <Route path="/settings" exact component={Loadable({loader: ()=>import('../pages/settings/index'), loading})} />
            <Route path="/agent" exact component={Loadable({loader: ()=>import('../pages/agent/index'), loading})} />
            <Route path="/recommended-agent" exact component={Loadable({loader: ()=>import('../pages/recommended-agent/index'), loading})} />
            <Route path="/history-agent" exact component={Loadable({loader: ()=>import('../pages/history-agent/index'), loading})} />
            <Route path="/result-agent/:id" exact component={Loadable({loader: ()=>import('../pages/result-agent/index'), loading})} />
            <Route path="/detail-agent/:from" exact component={Loadable({loader: ()=>import('../pages/detail-agent/index'), loading})} />
            <Route path="/remark" exact component={Loadable({loader: ()=>import('../pages/remark/index'), loading})} />
            <Route path="/zs" exact component={Loadable({loader: ()=>import('../pages/zs/index'), loading})} />
            <Route path="/recommended-zs" exact component={Loadable({loader: ()=>import('../pages/recommended-zs/index'), loading})} />
            <Route path="/history-zs" exact component={Loadable({loader: ()=>import('../pages/history-zs/index'), loading})} />
            <Route path="/result-zs/:id" exact component={Loadable({loader: ()=>import('../pages/result-zs/index'), loading})} />
            <Route path="/detail-zs/:from" exact component={Loadable({loader: ()=>import('../pages/detail-zs/index'), loading})} />
            <Route path="/search-policy" exact component={Loadable({loader: ()=>import('../pages/search-policy/index'), loading})} />
            <Route path="/content-policy/:id/:title" exact component={Loadable({loader: ()=>import('../pages/content-policy/index'), loading})} />
            {/*-- 没有匹配到路由跳转到首页 --*/}
            <Redirect from='/*' to='/'></Redirect>
        </Switch>
    </ConnectedRouter>)
}

export default RooterMap
