import { handleResultCode } from '../../service/service'
import apiService from '../../service/services/api'

const START_REVIEW_LIST = 'START_REVIEW_LIST'
const FINISH_REVIEW_LIST = 'FINISH_REVIEW_LIST'

const initState = {
    reviewList: [],
    reviewTotalPage: 0,
}

function startGetReviewList() {
    return {
        type: START_REVIEW_LIST
    }
}

function finishGetReviewList(params) {
    return {
        type: FINISH_REVIEW_LIST,
        reviewList: params.content,
        reviewTotalPage: params.totalPage
    }
}

export function getReviewList(params, cb) {
    return (dispatch) => {
        dispatch(startGetReviewList())
        apiService.review(params)
            .then((response) => {
                return handleResultCode(response)
            })
            .then((res) => {
                if (res.success) {
                    const { content } = res.data.data.pageInfo
                    dispatch(finishGetReviewList(res.data.data.pageInfo))
                    if (cb) {
                        cb(content)
                    }
                }
            })
            .catch(() => {
                return
            })
    }
}

export default function reviewReducer(state = initState, action) {
    switch (action.type) {
        case START_REVIEW_LIST:
            return {
                ...state
            }
        case FINISH_REVIEW_LIST:
            return {
                ...state,
                reviewList: action.reviewList,
                reviewTotalPage: action.reviewTotalPage
            }
        default:
            return state
    }
}
