import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import login from './reducers/login'
import historys from './reducers/history'
import historyNons from './reducers/history-non'
import calculate from './reducers/calculate'
import review from './reducers/review'
import result from './reducers/result'
import audit from './reducers/audit'
import industry from './reducers/industry'
import recommended from './reducers/recommended'
import recommendedNon from './reducers/recommended-non'
import companyInfo from './reducers/company-info'
import detail from './reducers/detail'
import agent from './reducers/agent'
import historyAgent from './reducers/history-agent'
import resultAgent from './reducers/result-agent'
import all from './reducers/all'
import zs from './reducers/zs'
import historyZs from './reducers/history-zs'
import resultZs from './reducers/result-zs'
import policyNew from './reducers/policy-new'
export default (history) => combineReducers({
    router: connectRouter(history),
    login,
    historys,
    historyNons,
    calculate,
    review,
    result,
    audit,
    industry,
    recommended,
    recommendedNon,
    companyInfo,
    detail,
    agent,
    historyAgent,
    resultAgent,
    all,
    zs,
    historyZs,
    resultZs,
    policyNew
})
