const SAVE_COMPANY_INFO = 'SAVE_COMPANY_INFO'
const CLEAR_COMPANY_INFO = 'CLEAR_COMPANY_INFO'

const initState = {
    companyInfoForm: sessionStorage.getItem('companyInfoForm') ? JSON.parse(sessionStorage.getItem('companyInfoForm')) : {},
}

export function saveCompanyInfo(params) {
    return {
        type: SAVE_COMPANY_INFO,
        companyInfoForm: params
    }
}

export function clearCompanyInfo() {
    return {
        type: CLEAR_COMPANY_INFO
    }
}

export default function companyInfoReducer(state = initState, action) {
    switch (action.type) {
        case SAVE_COMPANY_INFO:
            sessionStorage.setItem('companyInfoForm', JSON.stringify(action.companyInfoForm))
            return {
                ...state,
                companyInfoForm: action.companyInfoForm
            }
        case CLEAR_COMPANY_INFO:
            sessionStorage.removeItem('companyInfoForm')
            return {
                ...state,
                companyInfoForm: {}
            }
        default:
            return state
    }
}
